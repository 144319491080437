import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../authConfig";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

export { msalInstance };
