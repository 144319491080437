import { AccountResponse } from "../types/msAccount";
const GRAPH_ENDPOINT = "https://graph.microsoft.com";

// Generic GET for graph API
const get = async (url: string, accessToken: string) => {
  const headers = new Headers();
  if (accessToken) {
    headers.append("Authorization", `Bearer ${accessToken}`);
    const options = {
      method: "GET",
      headers: headers,
    };
    return fetch(`${GRAPH_ENDPOINT}${url}`, options);
  }
  throw new Error("No access token provided");
};

// Function for fetching user
const fetchUser = async (
  accessToken: string | null,
): Promise<AccountResponse> => {
  if (!accessToken) {
    throw new Error("Cannot find access token");
  }
  const response = await get(
    "/v1.0/me?$select=displayName,givenName,jobTitle,identities,mail,surname,userPrincipalName,onPremisesSamAccountName",
    accessToken,
  );
  if (!response || !response.ok) {
    console.log(response);
    throw new Error("Cannot find user");
  }
  return response.json();
};

export { fetchUser };
