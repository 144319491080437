export const PAGINATION_LABEL = {
  PREV: "Prev",
  NEXT: "Next",
  ELLIPSIS: "...",
};

export const METHOD_NOT_SUPPORTED = "Method is not supported";

export const HTTP_STATUS = {
  TRUE: "true",
  FALSE: "false",
};

export const DEFAULT_LIMIT = 10;

export const DEFAULT_OFFSET = 0;

export const DEFAULT_SHOW_RECORDS = 20;

export const TABLE_SIZE = 20;

export const MAX_RECORDS = 5000;

export const ULTRA_DB = "ULTRA";

export const XPONENT_DB = "XPONENT";

export const TS_SUMMARY = "TS List";

export const TS_ROUTING = "Routing";

export const TS_OPUSAGE = "Opusage";

export const TS_MRFORECAST = "MRForecast";

export const DATE_DISPLAY_FORMAT = "M/DD/YYYY, h:mm:ss A";
