import "./App.css";
import { Global } from "@emotion/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/common/Header";
import { loginRequest } from "./authConfig";
import {
  InteractionType,
  AccountInfo,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { useMsalAuthentication } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState, createContext } from "react";
import { fetchUser } from "./services/msgraph";
import moment from "moment";
import { AccountResponse } from "./types/msAccount";
import globalStyles from "./styles/globalStyles";
import HomePage from "components/summary";
export const AppToken = createContext("");

function App() {
  // Remove these lines to disable sso authentication, meaning everyone (non-cx users) can access to the site
  /*--------------------------------------------------------------------------------------------------------*/
  const { error } = useMsalAuthentication(InteractionType.Redirect);

  const { instance, accounts } = useMsal();
  const [account, setAccount] = useState<AccountInfo | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [authorizedToken, setAuthorizedToken] = useState<string | null>(null);
  const [userData, setUserData] = useState<AccountResponse | null>(null);

  useEffect(() => {
    async function requestProfileData() {
      try {
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });
        setAccount(response.account);
        setAccessToken(response.idToken);
        setAuthorizedToken(response.idToken);

        // Fetch extra data from API
        const userData = await fetchUser(response.accessToken);
        setUserData(userData);
        // ------------------------------------------------
        // Calculate the refresh time based on the expiry time
        const tokenExpiryTime = moment(response.expiresOn);
        const refreshTime = Math.max(
          tokenExpiryTime.diff(moment()) - 300000,
          0,
        ); // Refresh 5 minutes before expiry

        setTimeout(async () => {
          try {
            const response = await instance.acquireTokenSilent({
              ...loginRequest,
              account: accounts[0],
              forceRefresh: true,
            });
            setAccount(response.account);
            setAccessToken(response.idToken);
            setAuthorizedToken(response.idToken);

            // Fetch extra data from API
            const userData = await fetchUser(response.accessToken);
            setUserData(userData);
          } catch (error) {
            console.error("Error refreshing token:", error);
          }
        }, refreshTime);
        // -----------------------------------------------------------
      } catch (err) {
        if (err instanceof InteractionRequiredAuthError) {
          try {
            const response = await instance.acquireTokenSilent({
              ...loginRequest,
              account: accounts[0],
              forceRefresh: true,
            });
            setAccount(response.account);
            setAccessToken(response.idToken);
            setAuthorizedToken(response.idToken);
            // Fetch extra data from API
            const userData = await fetchUser(response.accessToken);
            setUserData(userData);
          } catch (error) {
            instance.loginRedirect({
              ...loginRequest,
            });
          }
        } else {
          instance.loginRedirect({
            ...loginRequest,
          });
        }
      }
    }
    if (accounts.length) {
      requestProfileData();
    }
  }, [accounts, accessToken, instance]);
  /*--------------------------------------------------------------------------------------------------------*/

  const context = {
    // accessToken: accessToken,
    authorizedToken: authorizedToken,
  };

  // const htmlTitle = `Task Server Support Tool`;

  return (
    <AppToken.Provider value={JSON.stringify(context)}>
      {/* <head>
        <link rel="icon" href="./assets/images/logo.svg" type="image/svg+xml" />
        <title>{htmlTitle}</title>
      </head> */}
      <Global styles={globalStyles} />
      <Router basename={process.env.PUBLIC_URL}>
        <Header account={account} />
        <div style={{ padding: "3rem 0rem", width: "100%" }}>
          <Routes>
            <Route path="/" element={authorizedToken && <HomePage />} />
          </Routes>
        </div>
      </Router>
    </AppToken.Provider>
  );
}

export default App;
