import { Content, List, Root, Trigger } from "@radix-ui/react-tabs";
import * as React from "react";
import { type ReactNode } from "react";

import { cn } from "../../../utils";
// import { useUrlSearchParamsWithKey } from '@/utils/useUrlSearchParams';

interface Option {
  label: string;
  value: string;
}

const TabsList = React.forwardRef<
  React.ElementRef<typeof List>,
  React.ComponentPropsWithoutRef<typeof List>
>(({ className, ...props }, ref) => (
  <List ref={ref} className={cn(className)} {...props} />
));
TabsList.displayName = List.displayName;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof Trigger>,
  React.ComponentPropsWithoutRef<typeof Trigger>
>(({ className, ...props }, ref) => (
  <Trigger
    ref={ref}
    className={cn(
      "inline-flex w-[200px] items-center justify-center whitespace-nowrap border-cx-utility-link-0 px-5 py-3.5 text-sm disabled:pointer-events-none disabled:opacity-50 data-[state=active]:border-b-4 data-[state=active]:bg-white data-[state=active]:font-bold data-[state=active]:text-cx-utility-link-0",
      className,
    )}
    {...props}
  />
));
TabsTrigger.displayName = Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof Content>,
  React.ComponentPropsWithoutRef<typeof Content>
>(({ className, ...props }, ref) => (
  <Content
    ref={ref}
    className={cn(
      "ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300",
      className,
    )}
    {...props}
  />
));
TabsContent.displayName = Content.displayName;

interface TabOptions extends Option {
  content: ReactNode;
}

interface TabsProps extends React.ComponentPropsWithRef<typeof Root> {
  tabOptions: TabOptions[];
  keepInUrl?: boolean;
  defaultValue?: string;
}

const Tabs = ({
  tabOptions,
  keepInUrl,
  defaultValue = tabOptions[0]?.value,
  ...props
}: TabsProps) => {
  // const [tabInUrl, setTabInUrl] = useUrlSearchParamsWithKey(
  //   'tab',
  //   defaultValue,
  // );
  const [tab, setTab] = React.useState(defaultValue);

  return (
    <Root
      value={tab}
      onValueChange={(v) => {
        setTab(v);
        // if (keepInUrl) {
        //   setTabInUrl(v);
        // }
      }}
      {...props}
    >
      <TabsList
        style={{
          margin: "1.3125rem 0 0.3125rem 0",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          padding: "0 1rem",
          // marginBottom: "0.7375rem",
        }}
      >
        {tabOptions.map((tabOption) => (
          <TabsTrigger
            key={tabOption.value}
            value={tabOption.value}
            className={tabOption.value === tab ? "tabActive" : ""}
          >
            {tabOption.label}
          </TabsTrigger>
        ))}
      </TabsList>
      {tabOptions.map((tabOption) => (
        <TabsContent key={tabOption.value} value={tabOption.value}>
          {tabOption.content}
        </TabsContent>
      ))}
    </Root>
  );
};

export default Tabs;
