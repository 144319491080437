declare const window: { env: any };

const config = Object.freeze({
  // TODO: REPLACE YOUR ENVIRONMENT VARIABLES HERE
  DEMO_ENV_VAR: window.env?.DEMO_ENV_VAR || "React template - default",
  AZURE_CLIENT_ID: window.env?.AZURE_CLIENT_ID,
  AZURE_TENANT_ID:
    window.env?.AZURE_TENANT_ID ||
    (window.env?.ENV && window.env?.ENV.startsWith("p0")
      ? "f62aca8c-2ba2-417b-a0c1-ab3f1020ccc4"
      : "4266ec6c-fe9f-4893-82e9-996189e0b81b"),
  API_HOST: window.env?.API_HOST || "/api",
});
export default config;
