import { useState, useEffect, useContext } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { TaskStatusRequest, TaskStatusResponseData } from "../types/api";
import { Button, DateTime, Line, Loader, Text, notify } from "../components";
import CheckBox2 from "../components/common/Checkbox";
import TableBox from "../components/common/TableBox";
import Tabs from "../components/common/Tabs";
import { DEFAULT_LIMIT, TS_SUMMARY } from "../constants";
import { COLORS } from "../styles/colors";
import { FONT_WEIGHT } from "../styles/fonts";
import { getTaskStatus } from "../utils/apiHelper";
import { AppToken } from "../App";
import MRforecastPage from "./mrforecast";
import OpusagePage from "./opusage";
import RoutingPage from "./routing";

interface ISearchForm {
  rowId?: number;
  server?: string;
  description?: string;
  status?: string;
  highLight?: boolean;
}

const HomePage = () => {
  const [isShowSpinner, setIsShowSpinner] = useState(false);
  const [searchResults, setSearchResults] = useState<TaskStatusResponseData>(
    [],
  );
  const [limit, setLimit] = useState(DEFAULT_LIMIT);
  const [offset, setOffSet] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [refreshTime, setRefreshTime] = useState("");
  const [isInit, setIsInit] = useState(true);
  const [highLight, setHighLight] = useState(false);
  const [alertColor, setAlertColor] = useState(true);
  const { handleSubmit, setValue } = useForm<ISearchForm>({});
  const { authorizedToken } = JSON.parse(useContext(AppToken));

  useEffect(() => {
    const getData = async () => {
      setIsShowSpinner(true);
      setTotalRecords(0);
      setRefreshTime("");
      const { errorMessage, response } = await getTaskStatus(
        {
          taskType: TS_SUMMARY,
          limit: limit.toString(),
          offset: "0",
          highLight: `${highLight}`,
        },
        authorizedToken,
      );

      if (errorMessage) {
        setIsShowSpinner(false);
        notify({
          message: errorMessage ?? "Error while loading page",
          type: "error",
        });
        return;
      }

      if (response) {
        setSearchResults(response.data);
        setTotalRecords(response.totalCount);
        setRefreshTime(response.refreshTime);
        setIsShowSpinner(false);
      }
    };
    if (isInit) {
      getData();
      setIsInit(false);
    }
  }, [limit, isInit, highLight, setValue]);

  useEffect(() => {
    fetchRefreshResults({
      limit,
      offset,
    });
  }, [highLight]);

  const fetchRefreshResults = async (request: TaskStatusRequest) => {
    setIsShowSpinner(true);
    setIsInit(false);
    if (request.limit) {
      setLimit(limit);
    }
    if (request.offset) {
      setOffSet(request.offset);
    }
    if (request.highLight) {
      setHighLight(request.highLight);
    }
    const modifiedRequest = {
      taskType: request.taskType || TS_SUMMARY,
      limit: request.limit?.toString() || limit.toString(),
      offset: request.offset?.toString() || offset.toString(),
      highLight: request.highLight?.toString() || `${highLight}`,
    };

    const { errorMessage, response } = await getTaskStatus(
      modifiedRequest,
      authorizedToken,
    );

    if (errorMessage) {
      setIsShowSpinner(false);
      notify({
        message: errorMessage ?? "Error while loading page",
        type: "error",
      });
      return;
    }

    if (response) {
      setSearchResults(response.data);
      setTotalRecords(response.totalCount);
      setRefreshTime(response.refreshTime);
      setIsShowSpinner(false);
    }
  };

  const onSubmit: SubmitHandler<ISearchForm> = () => {
    fetchRefreshResults({
      limit,
      offset,
      highLight,
    });
  };

  const handleChecked = () => {
    setHighLight(highLight ? false : true);
  };

  const showColor = () => {
    setAlertColor(!alertColor);
  };

  return (
    <>
      {isShowSpinner ? (
        <Loader
          style={{
            alignItems: "center",
            flex: 1,
          }}
        />
      ) : null}
      <Tabs
        keepInUrl
        tabOptions={[
          {
            value: "summary",
            label: "Summary",
            content: (
              <div
                style={{
                  width: "auto",
                  height: "auto",
                  opacity: isShowSpinner ? "0.5" : "1",
                  pointerEvents: isShowSpinner ? "none" : "auto",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    padding: "0 1rem",
                    marginBottom: "0.8375rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <div style={{ justifyContent: "space-between" }}>
                      <span
                        style={{
                          fontSize: "1.875rem",
                          color: COLORS.black,
                          fontWeight: FONT_WEIGHT.bold,
                        }}
                      >
                        <Text>Summary</Text>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    margin: "1.3125rem 0 0.3125rem 0",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    padding: "0 1rem",
                  }}
                >
                  <span
                    style={{
                      fontSize: "0.875rem",
                      color: COLORS.black1,
                      fontWeight: FONT_WEIGHT.regular,
                      width: "25%",
                    }}
                  >
                    <Text>{`Refresh Time: ${refreshTime}`}</Text>
                  </span>
                  <span
                    style={{
                      fontSize: "0.875rem",
                      color: COLORS.black1,
                      fontWeight: FONT_WEIGHT.regular,
                      width: "25%",
                    }}
                  >
                    <Text>
                      Current Time:
                      <DateTime />
                    </Text>
                  </span>
                  <span
                    style={{
                      fontWeight: FONT_WEIGHT.regular,
                      color: COLORS.black1,
                      fontSize: "0.875rem",
                      width: "20%",
                    }}
                  >
                    <CheckBox2
                      defaultChecked={alertColor}
                      name="showHighLight"
                      label="Show Highlight"
                      onChange={showColor}
                    />
                  </span>
                  <span
                    style={{
                      fontWeight: FONT_WEIGHT.regular,
                      color: COLORS.black1,
                      fontSize: "0.875rem",
                      width: "20%",
                    }}
                  >
                    <CheckBox2
                      defaultChecked={highLight}
                      name="highLight"
                      label="Show Highlight Only"
                      onChange={handleChecked}
                    />
                  </span>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Button
                      id="refresh"
                      title="Refresh"
                      type="submit"
                      variant="secondary"
                      style={{
                        position: "relative",
                        float: "inline-start",
                        marginLeft: "0.625rem",
                        textAlign: "center",
                        // width: "10%",
                        height: "45px",
                        width: "80px",
                      }}
                    />
                  </form>
                </div>
                <div
                  style={{
                    padding: "0 1rem",
                  }}
                >
                  <Line
                    commonStyles={{
                      marginTop: "0.875rem",
                      marginBottom: "0.8rem",
                    }}
                  />
                </div>
                <div
                  style={{
                    margin: "1.3125rem 0 0.3125rem 0",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    padding: "0 1rem",
                  }}
                >
                  <span
                    style={{
                      fontWeight: FONT_WEIGHT.medium,
                      color: COLORS.black1,
                      fontSize: "1rem",
                      paddingLeft: "0.1rem",
                    }}
                  >
                    <Text>{`No. of items: ${totalRecords}`}</Text>
                  </span>
                </div>
                <TableBox
                  searchResults={searchResults || []}
                  totalRecords={totalRecords}
                  limit={limit}
                  fetchRefreshResults={fetchRefreshResults}
                  alertColor={alertColor}
                />
              </div>
            ),
          },
          {
            value: "routing",
            label: "Routing",
            content: <RoutingPage />,
          },
          {
            value: "opusage",
            label: "Opusage",
            content: <OpusagePage />,
          },
          {
            value: "mrforecast",
            label: "MR Forecast",
            content: <MRforecastPage />,
          },
        ]}
      />
    </>
  );
};

export default HomePage;
