import { useEffect, useState, useContext } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AppToken } from "../App";
import {
  RoutingAllData,
  RoutingCountData,
  TaskStatusRequest,
  TaskStatusResponseData,
} from "../types/api";
import { Button, DateTime, Line, Loader, Text, notify } from "../components";
import CheckBox2 from "../components/common/Checkbox";
import RoutingAllBox from "../components/common/RoutingAllBox";
import RoutingCountBox from "../components/common/RoutingCountBox";
import TableBox from "../components/common/TableBox";
import { DEFAULT_LIMIT, TS_ROUTING } from "../constants";
import { COLORS } from "../styles/colors";
import { FONT_WEIGHT } from "../styles/fonts";
import { getRoutingStatus } from "../utils/apiHelper";

interface ISearchForm {
  rowId?: number;
  server?: string;
  description?: string;
  status?: string;
  highLight?: boolean;
}

const RoutingPage = () => {
  const [isShowSpinner, setIsShowSpinner] = useState(false);
  const [searchResults, setSearchResults] = useState<TaskStatusResponseData>(
    [],
  );
  const [limit, setLimit] = useState(DEFAULT_LIMIT);
  const [offset, setOffSet] = useState(0);
  const [countOffset, setCountOffSet] = useState(0);
  const [pendingOffset, setPendingOffSet] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [refreshTime, setRefreshTime] = useState("");
  const [isInit, setIsInit] = useState(true);
  const [highLight, setHighLight] = useState(false);
  const [alertColor, setAlertColor] = useState(true);
  const [countResults, setCountResults] = useState<RoutingCountData>([]);
  const [countTotal, setCountTotal] = useState(0);
  const [allResults, setAllResults] = useState<RoutingAllData>([]);
  const [allTotal, setAllTotal] = useState(0);
  const { handleSubmit, setValue } = useForm<ISearchForm>({});
  const { authorizedToken } = JSON.parse(useContext(AppToken));
  useEffect(() => {
    const getData = async () => {
      setIsShowSpinner(true);
      setTotalRecords(0);
      setCountTotal(0);
      setAllTotal(0);
      setRefreshTime("");
      const { errorMessage, response } = await getRoutingStatus(
        {
          taskType: TS_ROUTING,
          limit: limit.toString(),
          offset: "0",
          countOffset: "0",
          pendingOffset: "0",
          highLight: `${highLight}`,
        },
        authorizedToken,
      );

      if (errorMessage) {
        setIsShowSpinner(false);
        notify({
          message: errorMessage ?? "Error while loading page",
          type: "error",
        });
        return;
      }

      if (response) {
        setSearchResults(response.data.tsStatusData.tasksList);
        setTotalRecords(response.data.tsStatusData.totalCount);
        setRefreshTime(response.data.tsStatusData.refreshTime);
        setCountResults(response.data.countData.routingCountData);
        setCountTotal(response.data.countData.totalCount);
        setAllResults(response.data.allListData.routingAllData);
        setAllTotal(response.data.allListData.totalCount);
        setIsShowSpinner(false);
      }
    };
    if (isInit) {
      getData();
    }
  }, [limit, isInit, highLight, setValue]);

  useEffect(() => {
    fetchRefreshResults({
      limit,
      offset,
    });
  }, [highLight]);

  const fetchRefreshResults = async (request: TaskStatusRequest) => {
    setIsShowSpinner(true);
    setIsInit(false);
    if (request.limit) {
      setLimit(limit);
    }
    if (request.offset) {
      setOffSet(request.offset);
    }
    if (request.countOffset) {
      setCountOffSet(request.countOffset);
    }
    if (request.pendingOffset) {
      setPendingOffSet(request.pendingOffset);
    }
    if (request.highLight) {
      setHighLight(request.highLight);
    }
    const modifiedRequest = {
      taskType: request.taskType || TS_ROUTING,
      limit: request.limit?.toString() || limit.toString(),
      offset: request.offset?.toString() || offset.toString(),
      countOffset: request.countOffset?.toString() || countOffset.toString(),
      pendingOffset:
        request.pendingOffset?.toString() || pendingOffset.toString(),
      highLight: request.highLight?.toString() || `${highLight}`,
    };

    const { errorMessage, response } = await getRoutingStatus(
      modifiedRequest,
      authorizedToken,
    );

    if (errorMessage) {
      setIsShowSpinner(false);
      notify({
        message: errorMessage ?? "Error while loading page",
        type: "error",
      });
      return;
    }

    if (response) {
      setSearchResults(response.data.tsStatusData.tasksList);
      setTotalRecords(response.data.tsStatusData.totalCount);
      setRefreshTime(response.data.tsStatusData.refreshTime);
      setCountResults(response.data.countData.routingCountData);
      setCountTotal(response.data.countData.totalCount);
      setAllResults(response.data.allListData.routingAllData);
      setAllTotal(response.data.allListData.totalCount);
      setIsShowSpinner(false);
    }
  };

  const onSubmit: SubmitHandler<ISearchForm> = () => {
    fetchRefreshResults({
      limit,
      offset,
      countOffset,
      pendingOffset,
      highLight,
    });
  };

  const handleChecked = () => {
    setHighLight(highLight ? false : true);
  };

  const showColor = () => {
    setAlertColor(!alertColor);
  };

  return (
    <>
      {isShowSpinner ? (
        <Loader
          style={{
            alignItems: "center",
            flex: 1,
          }}
        />
      ) : null}
      <div
        style={{
          width: "auto",
          height: "auto",
          opacity: isShowSpinner ? "0.5" : "1",
          pointerEvents: isShowSpinner ? "none" : "auto",
          position: "relative",
        }}
      >
        <div
          style={{
            padding: "0 1rem",
            marginBottom: "0.9375rem",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <div style={{ justifyContent: "space-between" }}>
              <span
                style={{
                  fontSize: "1.875rem",
                  color: COLORS.black,
                  fontWeight: FONT_WEIGHT.bold,
                }}
              >
                <Text>Routing</Text>
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            margin: "0.9375rem 0 0.3125rem 0",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            padding: "0 1rem",
          }}
        >
          <span
            style={{
              fontSize: "0.875rem",
              color: COLORS.black1,
              fontWeight: FONT_WEIGHT.regular,
              width: "25%",
            }}
          >
            <Text>{`Refresh Time: ${refreshTime}`}</Text>
          </span>
          <span
            style={{
              fontSize: "0.875rem",
              color: COLORS.black1,
              fontWeight: FONT_WEIGHT.regular,
              width: "25%",
            }}
          >
            <Text>
              Current Time:
              <DateTime />
            </Text>
          </span>
          <span
            style={{
              fontWeight: FONT_WEIGHT.regular,
              color: COLORS.black1,
              fontSize: "0.875rem",
              width: "20%",
            }}
          >
            <CheckBox2
              defaultChecked={alertColor}
              name="showHighLight"
              label="Show Highlight"
              onChange={showColor}
            />
          </span>
          <span
            style={{
              fontWeight: FONT_WEIGHT.regular,
              color: COLORS.black1,
              fontSize: "0.875rem",
              width: "20%",
            }}
          >
            <CheckBox2
              defaultChecked={highLight}
              name="highLight"
              label="Show Highlight Only"
              onChange={handleChecked}
            />
          </span>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Button
              id="refresh"
              title="Refresh"
              type="submit"
              variant="secondary"
              style={{
                position: "relative",
                float: "inline-start",
                marginLeft: "0.625rem",
                textAlign: "center",
                // width: "10%",
                height: "45px",
                width: "80px",
              }}
            />
          </form>
        </div>
        <div
          style={{
            padding: "0 1rem",
          }}
        >
          <Line
            commonStyles={{
              marginTop: "0.875rem",
              marginBottom: "0.8rem",
            }}
          />
        </div>

        <TableBox
          searchResults={searchResults || []}
          totalRecords={totalRecords}
          limit={limit}
          fetchRefreshResults={fetchRefreshResults}
          alertColor={alertColor}
        />
        <div
          style={{
            padding: "0 1rem",
          }}
        >
          <Line
            commonStyles={{
              marginTop: "0.875rem",
              marginBottom: "0.8rem",
            }}
          />
        </div>
        <div
          style={{
            padding: "0 1rem",
            marginBottom: "0.8rem",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <div style={{ justifyContent: "space-between" }}>
              <span
                style={{
                  fontSize: "1.875rem",
                  color: COLORS.black,
                  fontWeight: FONT_WEIGHT.bold,
                }}
              >
                <Text>Routing Count</Text>{" "}
              </span>
            </div>
          </div>
        </div>

        <RoutingCountBox
          searchResults={countResults || []}
          totalRecords={countTotal}
          limit={limit}
          fetchRefreshResults={fetchRefreshResults}
          alertColor={alertColor}
        />
        <div
          style={{
            padding: "0 1rem",
          }}
        >
          <Line
            commonStyles={{
              marginTop: "0.875rem",
              marginBottom: "0.8rem",
            }}
          />
        </div>
        <div
          style={{
            padding: "0 1rem",
            marginBottom: "0.8rem",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <div style={{ justifyContent: "space-between" }}>
              <span
                style={{
                  fontSize: "1.875rem",
                  color: COLORS.black,
                  fontWeight: FONT_WEIGHT.bold,
                }}
              >
                <Text>Routing All Message</Text>
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            margin: "1.3125rem 0 0.3125rem 0",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            padding: "0 1rem",
          }}
        >
          <span
            style={{
              fontWeight: FONT_WEIGHT.medium,
              color: COLORS.black1,
              fontSize: "1rem",
              paddingLeft: "0.1rem",
            }}
          >
            <Text>{`No. of items: ${allTotal}`}</Text>
          </span>
        </div>
        <RoutingAllBox
          searchResults={allResults || []}
          totalRecords={allTotal > 500 ? 500 : allTotal}
          limit={limit}
          fetchRefreshResults={fetchRefreshResults}
          alertColor={alertColor}
        />
      </div>
    </>
  );
};

export default RoutingPage;
