import { css } from "@emotion/react";

import { COLORS } from "../styles/colors";

const globalStyles = css`
  html,
  body {
    padding: 0;
    margin: 0;
    font-family:
      -apple-system,
      BlinkMacSystemFont,
      Segoe UI,
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      Fira Sans,
      Droid Sans,
      Helvetica Neue,
      sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: ${COLORS.black};
    background-color: ${COLORS.white};
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  *,
  :after,
  :before {
    box-sizing: border-box;
  }

  input,
  textarea,
  button {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  img,
  svg {
    vertical-align: middle;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }

  table {
    border-collapse: collapse;
    caption-side: bottom;
  }

  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border: 0 solid;
    border-color: inherit;
  }

  th {
    text-align: -webkit-match-parent;
    text-align: inherit;
  }

  dl,
  ol,
  ul {
    margin-bottom: 1rem;
    margin-top: 0;
  }

  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled),
  button:not(:disabled) {
    cursor: pointer;
  }

  .css-jwxvis-Table th {
    padding: 0.2rem;
  }

  .pagination {
    display: inline-block;
  }

  .pagination button {
    color: #116f9a;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color 0.3s;
    border: 1px solid #ddd;
  }

  .pagination .css-gy73oi-Table {
    color: #ffffff;
  }

  .pagination .active {
    color: #fff;
  }

  .show {
    width: 50px;
    height: 30px;
    text-align: center;
  }

  .status {
    width: 80px;
    height: 30px;
    text-align: center;
    background-color: #c5d9cf;
    border: none;
  }

  .yellow {
    background-color: yellow;
  }

  .tab {
    border: none;
    text-align: center;
    background-color: ${COLORS.grey};
  }

  .tabActive {
    border: none;
    text-align: center;
    background-color: ${COLORS.green3};
    color: ${COLORS.white};
  }

  @media (prefers-color-scheme: dark) {
    html {
      text-decoration: none;
    }

    body {
      text-decoration: none;
    }
  }
`;

export default globalStyles;
