import { CSSObject } from "@emotion/react";

import { COLORS } from "../../../styles/colors";

const Line = ({ commonStyles }: { commonStyles?: CSSObject }) => (
  <div
    style={{
      minHeight: "0.063rem",
      backgroundColor: COLORS.grey1,
      border: "none",
    }}
  />
);

export default Line;
