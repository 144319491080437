import axios, { AxiosError, ResponseType } from "axios";
import env from "../config";
import { get } from "lodash";
import { stringify } from "qs";

import {
  ApiError,
  IMRForecastResponse,
  IOpusageResponse,
  IRoutingResponse,
  ITaskSearchResponse,
  TaskStatusQueryParams,
} from "../types/api";

export const to = async <T = unknown, E = unknown>(
  promise: Promise<T>,
): Promise<[E, undefined] | [undefined, T]> => {
  try {
    const result = await promise;
    return [undefined, result];
  } catch (e) {
    return [e as E, undefined];
  }
};

const apiTo = <T>(promise: Promise<T>) =>
  to<T, AxiosError<{ error: ApiError }>>(promise);

const URL_PREFIX = env.API_HOST;

const genericCaller = async <T>({
  url,
  params,
  method,
  headers,
  reqBody,
  responseType,
  urlPrefix = URL_PREFIX,
}: {
  url: string;
  params?: unknown;
  method: "GET" | "POST" | "PUT";
  headers?: object;
  reqBody?: unknown;
  responseType?: ResponseType;
  urlPrefix?: string;
}) => {
  const [error, response] = await apiTo(
    axios.request<T>({
      url: `${urlPrefix}${url}`,
      params,
      method,
      data: reqBody,
      headers: { ...headers },
      responseType,
    }),
  );

  if (error) {
    if (
      error.response?.status === 401 ||
      error.response?.status === 403 ||
      error.code === "ERR_NETWORK"
    ) {
      window.location.href = "/";
      return Promise.reject(error);
    } else {
      return {
        errorMessage: get(error, "response.data.error.details", "Error"),
      };
    }
  }

  return { response: response?.data };
};

export const getTaskStatusUrl = () => `${URL_PREFIX}/tasks`;

export const getTaskStatus = async (
  params: TaskStatusQueryParams,
  token: string | undefined,
) => {
  const newParams: TaskStatusQueryParams = {};
  Object.entries(params).forEach(([key, value]) => {
    if (value) newParams[key] = value;
  });
  return genericCaller<ITaskSearchResponse>({
    url: `${URL_PREFIX}/summary?${stringify(newParams)}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    urlPrefix: "",
  });
};

export const getRoutingStatus = async (
  params: TaskStatusQueryParams,
  token: string | undefined,
) => {
  const newParams: TaskStatusQueryParams = {};
  Object.entries(params).forEach(([key, value]) => {
    if (value) newParams[key] = value;
  });
  return genericCaller<IRoutingResponse>({
    url: `${URL_PREFIX}/routing?${stringify(newParams)}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    urlPrefix: "",
  });
};

export const getOpusageStatus = async (
  params: TaskStatusQueryParams,
  token: string | undefined,
) => {
  const newParams: TaskStatusQueryParams = {};
  Object.entries(params).forEach(([key, value]) => {
    if (value) newParams[key] = value;
  });
  return genericCaller<IOpusageResponse>({
    url: `${URL_PREFIX}/opusage?${stringify(newParams)}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    urlPrefix: "",
  });
};

export const getMRForecastStatus = async (
  params: TaskStatusQueryParams,
  token: string | undefined,
) => {
  const newParams: TaskStatusQueryParams = {};
  Object.entries(params).forEach(([key, value]) => {
    if (value) newParams[key] = value;
  });
  return genericCaller<IMRForecastResponse>({
    url: `${URL_PREFIX}/mrforecast?${stringify(newParams)}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    urlPrefix: "",
  });
};
