import { useMemo, useState } from "react";
import {
  Column,
  useBlockLayout,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";

import {
  OpusagePending,
  OpusagePendingData,
  TaskStatusRequest,
} from "../../../types/api";
import Text from "../Text";
import PaginationV2 from "../Pagination";
import { COLORS } from "../../../styles/colors";
import { FONT_WEIGHT } from "../../../styles/fonts";

const OpusageBox = ({
  searchResults,
  totalRecords,
  limit,
  fetchRefreshResults,
  alertColor,
}: {
  searchResults: OpusagePendingData;
  totalRecords: number;
  limit: number;
  fetchRefreshResults: (request: TaskStatusRequest) => void;
  alertColor?: boolean;
}): JSX.Element => {
  const COLUMNS = useMemo<Column<OpusagePending>[]>(
    () => [
      {
        accessor: "recordId",
        Header: () => (
          <span
            style={{
              fontWeight: FONT_WEIGHT.medium,
              fontSize: "0.925rem",
              color: COLORS.black1,
            }}
          >
            P_REC_ID
          </span>
        ),
        Cell: ({ value }) => <Text>{value || "--"}</Text>,

        width: "10%",
      },
      {
        accessor: "objId",
        Header: () => (
          <span
            style={{
              fontWeight: FONT_WEIGHT.medium,
              fontSize: "0.925rem",
              color: COLORS.black1,
            }}
          >
            OBJ_ID
          </span>
        ),
        Cell: ({ value }) => <Text>{value || "--"}</Text>,

        width: "10%",
      },
      {
        accessor: "dateUsed",
        Header: () => (
          <span
            style={{
              fontWeight: FONT_WEIGHT.medium,
              fontSize: "0.925rem",
              color: COLORS.black1,
            }}
          >
            DATETIME_USED
          </span>
        ),
        Cell: ({ value }) => <Text>{value || "--"}</Text>,
        width: "17%",
      },
      {
        accessor: "createTime",
        Header: () => (
          <span
            style={{
              fontWeight: FONT_WEIGHT.medium,
              fontSize: "0.925rem",
              color: COLORS.black1,
            }}
          >
            CREATE_DATETIME
          </span>
        ),
        Cell: ({ value }) => <Text>{value || "--"}</Text>,
        width: "17%",
      },
      {
        accessor: "operatorId",
        Header: () => (
          <span
            style={{
              fontWeight: FONT_WEIGHT.medium,
              fontSize: "0.925rem",
              color: COLORS.black1,
            }}
          >
            OPERATOR_ID
          </span>
        ),
        Cell: ({ value }) => <Text>{value || "--"}</Text>,
        width: "13%",
      },
      {
        accessor: "part",
        Header: () => (
          <span
            style={{
              fontWeight: FONT_WEIGHT.medium,
              fontSize: "0.925rem",
              color: COLORS.black1,
            }}
          >
            PART
          </span>
        ),
        Cell: ({ value }) => <Text>{value || "--"}</Text>,
        width: "10%",
      },
      {
        accessor: "serial",
        Header: () => (
          <span
            style={{
              fontWeight: FONT_WEIGHT.medium,
              fontSize: "0.925rem",
              color: COLORS.black1,
            }}
          >
            SERIAL
          </span>
        ),
        Cell: ({ value }) => <Text>{value || "--"}</Text>,
        width: "10%",
      },
      {
        accessor: "warning",
        Header: () => (
          <span
            style={{
              fontWeight: FONT_WEIGHT.medium,
              fontSize: "0.925rem",
              color: COLORS.black1,
            }}
          >
            WARN
          </span>
        ),
        Cell: ({ value }) => <Text>{value || "--"}</Text>,
        width: "13%",
      },
    ],
    [],
  );
  const { page, headerGroups, getTableProps, getTableBodyProps, prepareRow } =
    useTable(
      {
        columns: COLUMNS,
        data: searchResults,
      },
      useSortBy,
      usePagination,
      useSticky,
      useBlockLayout,
    );

  const [pageIndex, setPageIndex] = useState(0);
  const gotoPage = (updater: number) => {
    // If the passed index is outside of the valid pageIndex range, then this function will do nothing.
    setPageIndex(updater);
    fetchRefreshResults({
      limit,
      pendingOffset: updater * limit,
    });
  };

  const nextPage = () => {
    // If there are no pages or canNextPage is false, this function will do nothing.
    if (pageIndex < Math.ceil(totalRecords / limit) - 1) {
      setPageIndex((prev) => prev + 1);
      fetchRefreshResults({
        limit,
        pendingOffset: (pageIndex + 1) * limit,
      });
    }
  };

  const previousPage = () => {
    // If there are no pages or canPreviousPage is false, this function will do nothing.
    if (pageIndex > 0) {
      setPageIndex((prev) => prev - 1);
      fetchRefreshResults({
        limit,
        pendingOffset: (pageIndex - 1) * limit,
      });
    }
  };
  return (
    <div style={{ padding: "0 1rem" }}>
      {totalRecords > 0 ? (
        <>
          <table
            {...getTableProps()}
            style={{
              borderRadius: "0.625rem",
              fontSize: "2rem",
              borderTop: `0.0625rem solid ${COLORS.grey5}`,
              borderRight: `0.0625rem solid ${COLORS.grey5}`,
              borderLeft: `0.0625rem solid ${COLORS.grey5}`,
              // overflowX: 'scroll',
              borderCollapse: "collapse",
              // display: 'block',
              border: "0",
              borderSpacing: "0",
              cursor: "default",
              width: "100%",
            }}
          >
            <thead
              style={{
                position: "relative",
                top: 0,
                width: "100%",
                zIndex: 5,
              }}
            >
              {headerGroups.map((headerGroup) => {
                const { key, ...restHeaderGroupProps } =
                  headerGroup.getHeaderGroupProps();
                return (
                  <tr
                    key={key}
                    {...restHeaderGroupProps}
                    style={{
                      height: "3.75rem",
                      lineHeight: "3.125rem",
                    }}
                  >
                    {headerGroup.headers.map((column) => {
                      const { key: thKey, ...restThProps } =
                        column.getHeaderProps(column.getSortByToggleProps());

                      return (
                        <th
                          key={thKey}
                          {...restThProps}
                          style={{
                            width: column.width,
                            letterSpacing: "0.019rem",
                            paddingLeft: "0.5rem",
                            color: COLORS.black,
                            fontSize: "0.875rem",
                            fontWeight: FONT_WEIGHT.regular,
                            padding: "0.3125rem",
                            overflow: "hidden",
                          }}
                        >
                          {column.render("Header")}
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>

            <tbody {...getTableBodyProps()} id="tableBody">
              {page.map((row) => {
                prepareRow(row);
                const { key, ...restRowProps } = row.getRowProps();
                return (
                  <tr
                    key={key}
                    id="tableRow"
                    {...restRowProps}
                    style={{
                      fontSize: "0.875rem",
                      display: "inline-block !important",
                      width: "100%",
                      letterSpacing: "0.019rem",
                      borderBottom: `0.0625rem solid ${COLORS.grey10}`,
                    }}
                  >
                    {row.cells.map((cell) => {
                      const { key: tdKey, ...restCellProps } =
                        cell.getCellProps();
                      return (
                        <td
                          className={
                            alertColor && cell?.row?.values?.warning === "WARN"
                              ? "yellow"
                              : ""
                          }
                          key={tdKey}
                          id="tableData"
                          {...restCellProps}
                          style={{
                            overflowWrap: "break-word",
                            padding: "0.5rem 0.4rem 0.5rem 0.5rem",
                            letterSpacing: "0.019rem",
                            width: cell.column.width,
                            minWidth: cell.column.minWidth,
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <PaginationV2
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageIndex={pageIndex}
            noOfPages={Math.ceil(totalRecords / limit)}
            // scrollToView={() => myTableRef?.current?.scrollIntoView()}
          />
        </>
      ) : null}
    </div>
  );
};

export default OpusageBox;
