import styled, { keyframes } from "styled-components";
import { COLORS } from "../../../styles/colors";

const spin = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`;

export const Loader = styled.div`
  border: 0.2em solid rgba(0, 0, 0, 0.1);
  border-top: 0.2em solid #767676;
  border-radius: 50%;
  width: 2.28571429rem;
  height: 2.28571429rem;
  animation: ${spin} 1000ms ease infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
  width: "3.0rem";
  height: "3.0rem";
  border: "5px solid";
  bordercolor: ${COLORS.grey5};
  borderradius: "50%";
  bordertopcolor: ${COLORS.grey4};
  margin: "0.5rem 0rem 0.5rem 0rem";
`;

export default Loader;
