/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment } from "react";

import { PAGINATION_LABEL } from "../../../constants";
import { COLORS } from "../../../styles/colors";

const PaginationItem = ({
  label,
  isActive,
  isDisabled,
  isEllipsis,
  isPrevNextText,
  onClick,
}: {
  label: string | number;
  isActive?: boolean;
  isEllipsis?: boolean;
  isDisabled?: boolean;
  isPrevNextText?: boolean;
  onClick?: () => void;
}) => {
  return (
    <li role="presentation" onClick={onClick}>
      <span
        role="button"
        tabIndex={0}
        css={{
          "li:first-of-type &": {
            borderBottomLeftRadius: "0.25rem",
            borderTopLeftRadius: "0.25rem",
          },
          "li:last-of-type &": {
            borderTopRightRadius: "0.25rem",
            borderBottomRightRadius: "0.25rem",
          },
          height: "2.25rem",
          width: isPrevNextText ? "3.375rem" : "3.25rem",
          border: `0.063rem solid ${COLORS.grey8}`,
          textAlign: "center",
          color: COLORS.blue5,
          textDecoration: "none",
          backgroundColor: "transparent",
          fontSize: "0.875rem",
          display: "block",
          userSelect: "none",
          transition:
            "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
          cursor: "pointer",
          ...(!isDisabled &&
            !isActive && {
              ":hover": {
                color: COLORS.blue5,
                backgroundColor: COLORS.grey8,
                outline: "none",
                boxShadow: "none",
              },
            }),
          ...(isActive && {
            color: COLORS.white,
            backgroundColor: COLORS.blue5,
            borderColor: COLORS.grey4,
          }),
          ...(isDisabled && {
            borderColor: COLORS.grey5,
            backgroundColor: COLORS.grey20,
            color: COLORS.grey18,
            pointerEvents: "none",
          }),
          ...(isEllipsis && {
            pointerEvents: "none",
          }),
        }}
      >
        <span>{label}</span>
      </span>
    </li>
  );
};

const PaginationV2 = ({
  gotoPage,
  nextPage,
  previousPage,
  pageIndex,
  noOfPages,
  scrollToView,
}: {
  gotoPage: (updater: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  pageIndex: number;
  noOfPages: number;
  scrollToView?: () => void;
}): JSX.Element => {
  const filterPages = (visiblePages: number[], totalPages: number) => {
    return visiblePages.filter((page) => page <= totalPages);
  };

  const getVisiblePages = (page: number, total: number) => {
    if (total < 7) {
      return filterPages([1, 2, 3, 4, 5, 6], total);
    }
    if (page % 5 >= 0 && page > 4 && page + 2 < total) {
      return [1, page - 1, page, page + 1, total];
    }
    if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
      return [1, total - 3, total - 2, total - 1, total];
    }
    return [1, 2, 3, 4, 5, total];
  };

  const canPreviousPage = pageIndex - 1 >= 0;
  const canNextPage = pageIndex + 1 < noOfPages;

  return (
    <div>
      {noOfPages > 1 ? (
        <section
          css={css`
            .pagination > .active > a {
              color: ${COLORS.white};
              background-color: ${COLORS.grey3} !important;
              border-color: ${COLORS.grey4} !important;
            }

            ul.pagination li a:hover {
              background-color: ${COLORS.white};
            }

            ul.pagination li a.active,
            ul.pagination li a:focus {
              color: ${COLORS.white};
              background-color: ${COLORS.grey3};
              outline: none;
              box-shadow: none;
            }
          `}
        >
          <div
            style={{
              display: "flex",
              marginTop: "1.5rem",
              fontSize: "0.875rem",
              lineHeight: "2.25rem",
            }}
          >
            <ul
              style={{
                display: "flex",
                listStyle: "none",
                paddingLeft: 0,
              }}
            >
              <PaginationItem
                label={PAGINATION_LABEL.PREV}
                isDisabled={!canPreviousPage}
                isPrevNextText
                onClick={() => {
                  previousPage();
                  if (scrollToView) scrollToView();
                }}
              />
              {getVisiblePages(pageIndex + 1, noOfPages).map(
                (page, index, array) => {
                  const isEllipsis = array[index - 1] + 1 < page;

                  return (
                    <Fragment key={page}>
                      {isEllipsis && (
                        <PaginationItem
                          label={PAGINATION_LABEL.ELLIPSIS}
                          isEllipsis
                        />
                      )}

                      <PaginationItem
                        label={page}
                        isActive={pageIndex + 1 === page}
                        onClick={() => {
                          //   page--;
                          gotoPage(page - 1);
                          if (scrollToView) scrollToView();
                        }}
                      />
                    </Fragment>
                  );
                },
              )}
              <PaginationItem
                label={PAGINATION_LABEL.NEXT}
                isDisabled={!canNextPage}
                isPrevNextText
                onClick={() => {
                  nextPage();
                  if (scrollToView) scrollToView();
                }}
              />
            </ul>
          </div>
        </section>
      ) : null}
    </div>
  );
};

export default PaginationV2;
