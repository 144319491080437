export const FONT_WEIGHT = {
  light: "300",
  regular: "400",
  medium: "500",
  bold: "700",
};

export const fontWeight = {
  light: "Cathay Sans Light",
  regular: "Cathay Sans Regular",
  medium: "Cathay Sans Medium",
  bold: "Cathay Sans Bold",
};
