import { CSSObject, css, keyframes } from "@emotion/react";

import { COLORS } from "../../../styles/colors";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = ({
  commonStyles,
  children,
  ...props
}: {
  children?: React.ReactNode;
  commonStyles?: CSSObject;
}) => (
  <div
    css={css`
      animation: ${spin} 1000ms ease infinite;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 999;
    `}
  >
    <div
      style={{
        width: "3.0rem",
        height: "3.0rem",
        border: "5px solid",
        borderColor: COLORS.grey5,
        borderRadius: "50%",
        borderTopColor: COLORS.grey4,
        margin: "0.5rem 0rem 0.5rem 0rem",
      }}
      {...props}
    >
      {children}
    </div>
  </div>
);

export default Spinner;
